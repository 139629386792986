import { useEffect, useState } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from './services'

const OTHERS_OPTION_ID = 9

const useSelectMacroGoal = () => {
  const { changeLeadData, leadData } = useLead()

  const [submitLoading, setSubmitLoading] = useState(false)
  const [options, setOptions] = useState([])

  const handleGetStep = () => {
    services
      .getMacroGoal()
      .then(({ data, previous_step }) => {
        setOptions(data)

        if (!leadData.variant && leadData.vwoVariant !== 'A') {
          changeLeadData({ previousStep: '/proposta/telefone' })
          return
        }

        changeLeadData({
          previousStep: previous_step
        })
      })
      .catch((error) => {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectMacroGoal | handleGetStep',
          payload
        })
      })
  }

  useEffect(() => {
    handleGetStep()
  }, [])

  const onSubmit = async ({ goal }) => {
    setSubmitLoading(true)

    if (Number(goal) === OTHERS_OPTION_ID) {
      return goTo('select-macro-goal-others')
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-macro-objetivo',
      detail: 'click-step-macro-objetivo',
      customLayer: {
        description: `objetivo-id-${goal}`
      }
    })

    const payload = { macro_goal_id: Number(goal) }

    return services
      .putMacroGoal(payload)
      .then(({ next_step }) => {
        changeLeadData({ macro_goal_id: goal })
        goTo(next_step)
      })
      .catch((error) => {
        const payload = {
          error: error?.message,
          requestURL: error?.request?.responseURL
        }

        DDPageError({
          name: 'useSelectMacroGoal | onSubmit',
          payload
        })
      })
      .finally(() => setSubmitLoading(false))
  }

  return { onSubmit, options, submitLoading }
}

export default useSelectMacroGoal
