import { useForm } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { IdsRadioButton, IdsIcon } from '@ids/react'
import { Navigation, If } from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import './SelectMacroGoal.scss'
import useSelectMacroGoal from './hooks'

const SelectMacroGoal = ({ pageContext }) => {
  const initialValues = { goal: '' }
  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { field } = useController({
    name: 'goal',
    control
  })

  const { onSubmit, submitLoading, options } = useSelectMacroGoal()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={submitLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 20px 0"
              widthDesktop="65%"
              height="64px"
              heightDesktop="40px"
            />
            <Shimmer margin="10px 0 20px 0" height="48px" />
            <Shimmer height="48px" />
            <Shimmer margin="20px 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 20px 0" height="48px" />
            <Shimmer margin="0 0 30px 0" height="86px" />
            <Shimmer margin="30px 0 0 0" widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">
              Conta pra gente qual é o seu objetivo?
            </h1>
            <form className="form macro-goal" onSubmit={handleSubmit(onSubmit)}>
              {options &&
                options.map(({ id, icon, name }) => {
                  return (
                    <IdsRadioButton
                      {...field}
                      key={id}
                      value={id}
                      name="goal"
                      onChange={(e) => field.onChange(e)}
                    >
                      <IdsIcon variant="neutral">{icon}</IdsIcon>
                      {name}
                    </IdsRadioButton>
                  )
                })}
              <span className="macro-goal__disclaimer">
                Fique tranquilo. As respostas serão utilizadas para montar a
                melhor proposta para você e não afetarão o valor e as taxas de
                sua proposta.
              </span>
              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!field.value}
                nextButtonId="macro-goal-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default SelectMacroGoal
